import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
	{ path: '', redirectTo: 'home', pathMatch: 'full' },
	{ path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule) },
	{
		path: 'perfil',
		loadChildren: () => import('./perfil/perfil.module').then(m => m.PerfilPageModule)
	},
	{
		path: 'agenda',
		loadChildren: () => import('./agenda/agenda.module').then(m => m.AgendaPageModule)
	},
	{
		path: 'checkin',
		loadChildren: () => import('./checkin/checkin.module').then(m => m.CheckinPageModule)
	},
	{
		path: 'cancelamento',
		loadChildren: () => import('./cancelamento/cancelamento.module').then(m => m.CancelamentoPageModule)
	},
	{
		path: 'visita-medidas',
		loadChildren: () => import('./visita-medidas/visita-medidas.module').then(m => m.VisitaMedidasPageModule)
	},
	{
		path: 'input-temperatura',
		loadChildren: () => import('./input-temperatura/input-temperatura.module').then(m => m.InputTemperaturaPageModule)
	},
	{
		path: 'input-bpm',
		loadChildren: () => import('./input-bpm/input-bpm.module').then(m => m.InputBpmPageModule)
	},
	{
		path: 'checkout',
		loadChildren: () => import('./checkout/checkout.module').then(m => m.CheckoutPageModule)
	},
	{
		path: 'input-oxigenacao',
		loadChildren: () => import('./input-oxigenacao/input-oxigenacao.module').then(m => m.InputOxigenacaoPageModule)
	},
	{
		path: 'input-pressao-arterial',
		loadChildren: () => import('./input-pressao-arterial/input-pressao-arterial.module').then(m => m.InputPressaoArterialPageModule)
	},
	{
		path: 'input-glicemia',
		loadChildren: () => import('./input-glicemia/input-glicemia.module').then(m => m.InputGlicemiaPageModule)
	},
	{
		path: 'input-peso',
		loadChildren: () => import('./input-peso/input-peso.module').then(m => m.InputPesoPageModule)
	},
	{
		path: 'input-massa-magra',
		loadChildren: () => import('./input-massa-magra/input-massa-magra.module').then(m => m.InputMassaMagraPageModule)
	},
	{
		path: 'input-gordura-visceral',
		loadChildren: () => import('./input-gordura-visceral/input-gordura-visceral.module').then(m => m.InputGorduraVisceralPageModule)
	},
	{
		path: 'formulario-bem-estar',
		loadChildren: () => import('./formulario-bem-estar/formulario-bem-estar.module').then(m => m.FormularioBemEstarPageModule)
	},
	{
		path: 'observacao-monitora',
		loadChildren: () => import('./observacao-monitora/observacao-monitora.module').then(m => m.ObservacaoMonitoraPageModule)
	},
	{
		path: 'assistidos',
		loadChildren: () => import('./assistidos/assistidos.module').then(m => m.AssistidosPageModule)
	},
	{
		path: 'assistido/historico/:id',
		loadChildren: () => import('./historico-assistido/historico-assistido.module').then(m => m.HistoricoAssistidoPageModule)
	},
	{
		path: 'assistido/:id',
		loadChildren: () => import('./info-assistido/info-assistido.module').then(m => m.InfoAssistidoPageModule)
	},
	{
		path: 'nova-visita',
		loadChildren: () => import('./nova-visita/nova-visita.module').then(m => m.NovaVisitaPageModule)
	},
	{
		path: 'assistido/nova-visita/:id',
		loadChildren: () => import('./nova-visita-assistido/nova-visita-assistido.module').then(m => m.NovaVisitaAssistidoPageModule)
	},
  {
    path: 'ajuda',
    loadChildren: () => import('./ajuda/ajuda.module').then( m => m.AjudaPageModule)
  },
  {
    path: 'trial',
    loadChildren: () => import('./trial/trial.module').then( m => m.TrialPageModule)
  },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
	],
	exports: [RouterModule]
})
export class AppRoutingModule { }
