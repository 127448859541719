import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SERVER_URL } from '../../environments/environment'
import { HTTP } from '@ionic-native/http/ngx';

@Injectable()
export class LoginService {
    constructor(private http: HttpClient, private httpNative: HTTP ) {}

    checkCPF(cpf: String):Promise<any>{
        return this.httpNative.post(SERVER_URL, {
            cpf
        }, {});
    }
}