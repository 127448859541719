import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Uid } from '@ionic-native/uid/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { Sim } from '@ionic-native/sim/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { LoginService } from "./_services_/login";

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module'
import { HttpClientModule } from '@angular/common/http';
import { HTTP } from '@ionic-native/http/ngx';
import { HttpInterceptModule } from "./_services_/access/http-intercept.module";
import { CardMonitoraPerfilComponent } from "./_components_/menu/card-monitora-perfil/card-monitora-perfil.component";

import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';

registerLocaleData(localePt);

@NgModule({
    declarations: [
        AppComponent,
        CardMonitoraPerfilComponent
    ],
    entryComponents: [
        
    ],
    exports:[
        CardMonitoraPerfilComponent
    ],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        HttpInterceptModule,
    ],
    providers: [
        StatusBar,
        SplashScreen,
        Uid,
        AndroidPermissions,
        Sim,
        Geolocation,
        LoginService,
        HTTP,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: LOCALE_ID, useValue: 'pt-BR' }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
