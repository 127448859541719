import { Component, DoCheck } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';

import {
	Plugins
} from '@capacitor/core';

const { Storage } = Plugins;

@Component({
	selector: 'app-card-monitora-perfil',
	templateUrl: './card-monitora-perfil.component.html',
	styleUrls: ['./card-monitora-perfil.component.scss'],
})
export class CardMonitoraPerfilComponent implements DoCheck {
	monitora: any

	constructor(
		private router: Router,
		private menu: MenuController
	) { }

	ngDoCheck() {
		this._getMonitora()
	}

	async sair() {
		await Storage.remove({ key: 'token' })
		await Storage.remove({ key: 'refresh' })
		await Storage.remove({ key: 'visita' })
		await Storage.remove({ key: 'regvisita' })
		await Storage.remove({ key: 'monitora' })

		this.menu.close('geral')
		this.router.navigate(['/home'])
	}

	async open(link: string) {
		this.menu.close('geral')
		this.router.navigate([link])
	}

	_getMonitora() {
		let monitora = JSON.parse(localStorage.getItem('_cap_monitora'))
		this.monitora = monitora

	}
}
