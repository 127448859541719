import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss']
})
export class AppComponent {
	currentLanguage: string = "default.selectLangOptions." + localStorage.getItem("language") || "pt-BR"

	constructor(
		private platform: Platform,
		private splashScreen: SplashScreen,
		private statusBar: StatusBar,
		private translate: TranslateService,
	) {
		this.initializeApp()
		// this.setFullscreen()
	}

	initializeApp() {
		this.platform.ready().then(() => {
			this.translate.setDefaultLang(localStorage.getItem("language") || "pt-BR");
			this.statusBar.styleDefault();
			setTimeout(() => this.splashScreen.hide(), 2000)
			// this.splashScreen.hide();

			this.platform.backButton.subscribeWithPriority(1, () => { });
		});
	}
	
	switchLanguage(event: CustomEvent) {
		const lang = event.detail.value || 'pt-BR'
		localStorage.setItem("language", lang);		
		this.translate.use(lang);
	}
}
