import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import {
	HttpEvent,
	HttpInterceptor,
	HttpHandler,
	HttpRequest
}
from '@angular/common/http';
import { Plugins } from '@capacitor/core';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { TokenService } from "./token.service";

const { Storage } = Plugins;

@Injectable({
	providedIn: 'root'
})
export class HttpInterceptService implements HttpInterceptor{

	constructor(
		private router: Router,
		private tokenService: TokenService,
	) { }

	intercept(
		req: HttpRequest<any>, 
		next: HttpHandler
	): Observable<HttpEvent<any>> {

		return next.handle(req).pipe(catchError( err => {
			
			if ([401, 403].includes(err.status)) {
				try {
					return this.cloneReq(req, next)
				} catch (err) {
					this.router.navigate(['/home']);
				}
            }

			return throwError(err);
		}))
	}

	private async cloneReq(req, next){
		let newToken = await this.tokenService.renewToken()

		req = req.clone({
			setHeaders: {
				'Authorization': `Bearer ${newToken}`
			}
		});

		return next.handle(req);
	}
}
