import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Plugins } from '@capacitor/core';
import { SERVER_URL } from '../../../environments/environment'
import { Router } from '@angular/router';

const { Storage } = Plugins;

@Injectable({
	providedIn: 'root'
})
export class TokenService {

	constructor(
		private http: HttpClient,
		private router: Router,
	){}
	
	async getToken(){
		let token = await Storage.get({ key: 'refresh' })
		return token.value
	}

	async renewToken(){
		let token = await Storage.get({ key: 'refresh' })

		try {
			let newToken = await this.sendToken(token.value)
			return newToken
		} catch (err) {
			throw err
		}
	}

	private async sendToken(token: string): Promise<string>{

		let formReq = new FormData().append('refresh', token)
		let req = this.http.post(`${SERVER_URL}/api/token/refresh`, formReq, {})

		return new Promise( (resolve, reject) => {
			req.subscribe(async (data: { access:string, refresh:string }) => {
				if(data.access){
					await Storage.set({
						key: 'token', 
						value: data.access
					})
					resolve(data.access)
				}
				reject(new Error('Token not generated.'))
			}, async(err) => {

				await Storage.remove({
					key: 'token'
				})

				await Storage.remove({
					key: 'refresh'
				})

				this.router.navigate(['/home']);
				reject(new Error('Token invalid.'))
			})
		})
	}
}
