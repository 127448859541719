import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss']
})
export class AppComponent {
	constructor(
		private platform: Platform,
		private splashScreen: SplashScreen,
		private statusBar: StatusBar
	) {
		this.initializeApp()
		// this.setFullscreen()
	}

	initializeApp() {
		this.platform.ready().then(() => {
			this.statusBar.styleDefault();
			setTimeout(() => this.splashScreen.hide(), 2000)
			// this.splashScreen.hide();

			this.platform.backButton.subscribeWithPriority(1, () => { });
		});
	}
}
